<template>
  <b-modal
    id="modal-invoice-create-select-ticket"
    size="xl"
    centered
    header-bg-variant="light-info"
    no-close-on-backdrop
    no-close-on-esc
    body-class="p-0"
    footer-class="justify-content-between"
    title-class="h2 font-weight-bolder text-dark"
    :title="$t('invoice.popup.chooseTicketTitle')"
    hide-footer
  >
    <InvoiceSelectTicketCard :is-modal="true" />
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    InvoiceSelectTicketCard: () => import('@/views/invoices/components/InvoiceSelectTicketCard.vue'),
  },

  props: {
    previousSelectedTicketIds: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    return {
    }
  },
}
</script>
