var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-invoice-create-select-ticket",
      "size": "xl",
      "centered": "",
      "header-bg-variant": "light-info",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "body-class": "p-0",
      "footer-class": "justify-content-between",
      "title-class": "h2 font-weight-bolder text-dark",
      "title": _vm.$t('invoice.popup.chooseTicketTitle'),
      "hide-footer": ""
    }
  }, [_c('InvoiceSelectTicketCard', {
    attrs: {
      "is-modal": true
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }